import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

// module.exports = {
// export default {
const zh = {
    language: {
        language: '语言',
        changeLanguage: '切换多语言成功',
        english: 'English',
        chinese: '简体中文'
    },
    app: {
        // name: 'AIScene Cloud Platform',
        // title: 'AIScene Cloud Platform'
        name: '智能照明管理云平台',
        title: '智能照明管理云平台'
    },
    user: {
        login: '登录',
        loginLoading: '登录中...',
        logout: '退出登录',
        username: '用户名',
        password: '密码',
        changePassword: '修改密码',
        accountCancellation: '注销账号',
        accountCancellationConfirm: '提交申请，删除所有数据，永久注销',
        email:'邮箱',
        pleaseEnterEmail:'请输入邮箱',
        pleaseEnterCurrentPassword:'请输入当前密码',
        currentPassword:'当前密码',
        newPassword:'新密码',
        confirmNewPassword:'密码再次确认',
        pleaseEnterNewPassword:'请输入新密码',
        reEnterYourNewPassword:'再次确认新密码',
        PleasereEnterYourNewPassword:'请再次输入新密码',
        twoPasswordNotMatch:'两次输入不一致',
        verificationCode:'验证码',
        pleaseEnterVerificationCode:'请输入验证码',
        sendVerificationCode:'发送验证码',
        secondsAgain:'s后获取',
        verificationCodeSendedSuccessfully:'验证码发送成功',
        changedSuccessfully:'修改成功',
        rememberMe:"记住我"
    },
    menu: {
        workbench: '工作面板',
        feedback:'反馈意见',
        login:'登录',
        appDownload:'App下载',
        meshManagement:'店铺管理',
        meshUserManagement:'店铺用户管理',
        assetsManagement:'资产管理',
        deviceManagement:'设备管理',
        aiLight:'智能灯',
        luminaire:'灯具',
        mobileDevice:'移动设备',
        firmwareManagement:'固件管理',
        appsManagement:'应用管理',
        statisticsManagement:'商品热度数据',
        customerStatistics:'商品热度',
        storePopularity:'店铺热度',
        allStoresPopularity:'跨店对比',
        popularityMap:'热度图',
        heatmap:'热力图',
        storeHeatmap:'店铺热力图',
        peopleManagement:'People Management',
        peopleCounting:'客流统计',
        storePeople:'店铺客流',
        currentHeatmap:'实时热力图',
        walkInCount:'进店客流',
        userCenter:'用户中心',
        roleManagement:'角色管理',
        deptManagement:'部门管理',
        postManagement:'岗位管理',
        staffManagement:'员工管理',
        agentManagement:'代理商管理',
        agentPackage:'代理套餐',
        menuManagement:'菜单管理',
        platformSettings:'平台设置',
        parameterSettings:'参数设置',
        dictManagement:'字典管理',
        areaManagement:'地区管理',
        shipmentManagement:'出货管理',
        shipmentCalibration:'出货标定',
        userAdvancedSettings:'用户高级设置',
        kailinks:'智谋纪云物联网',
        rkproductManagement:'产品管理',
        rkdeviceManagement:'设备管理'
    },
    components:{
        confirm:'确定',
        cancel:'取消',
        search:'查询',
        clear:'清空',
        return:'返回',
        delete:'删除',
        operation:'操作',
        content:'内容',
        index:'序号',
        view:'查看',
        email:'邮箱',
        add:'新增',
        yes:'是',
        no:'否',
        download:'下载',
        uploading:'上传中',
        downloading:'下载中',
        tips:'提示',
        addedSuccessfully:'添加成功',
        setSuccessfully:'设置成功',
        modifiedSuccessfully:'修改成功',
        deleteItQuestion:'是否确认删除？',
        verifyItQuestion:'是否确认平台验证通过？',
        closeDialogQuestion:'确认关闭对话框？',
        deletedSuccessfully:'删除成功',
        verifiedSuccessfully:'验证通过',
        configuredSuccessfully:'配置成功',
        copiedSuccessfully:'复制成功',
        numberUnit:'个',
        downloadedSuccessfully:'下载成功',
    },
    control:{
        pickADay:'请选择时间',
        today:'今天',
        yesterday:'昨天',
        aWeekAgo:'一周前',
        startDate:'选择开始时间',
        endDate:'选择结束时间',
        versionNumber:'版本号',
        pleaseEnterVersion:'请输入版本号',
        file:'文件',
        dragFileHereOr:'将文件拖到此处，或',
        clickUpload:'点击上传',
        updateContent:'更新内容',
        inputUpdateContent:'请输入更新内容',
        updateContentEnglish:'更新内容(英文)',
        inputUpdateContentEnglish:'请输入英文更新内容',
        firmwareType:'固件类型',
        newFirmware:'新型固件',
        newFirmwareJson:'新型固件JSON',
        inputJson:'请输入JSON',
        isForceUpdate:'是否强制更新',
        selectfirmwareType:'请选择固件类型',
        pleaseUploadFile:'请上传文件',
        uploadedSuccessfully:'上传成功',
        uploadedFailure:'上传失败',
        packageType:'包类型',
        packageTypeItem1:'整包',
        packageTypeItem2:'分包',
        firmwareName:'固件名称',
        firmwareProduct:'所属产品',
        firmwareModule:'所属模块',
        firmwareModuleItem1:'默认',
        signatureAlgoithm:'签名算法',
        signatureAlgoithmItem0:'无',
        signatureAlgoithmItem1:'MD5',
        signatureAlgoithmItem2:'SHA265',
        platformVerify:'平台验证',
    },
    mesh:{
        meshName:'店铺名称',
        email:'邮箱',
        userNickname:'用户昵称',
        userEmail:'用户邮箱',
        adminEmail:'管理员邮箱',
        adminNickname:'管理员昵称',
        memberNickname:'成员昵称',
        memberEmail:'成员邮箱/电话',
        memberNumber:'成员数量',
        groupNumber:'分组数量',
        content:'内容',
        operation:'操作',
        view:'查看',
        dissolve:'解散',
        nickname:'昵称',
        account:'账号',
        registerDate:'注册日期',
        to:'至',
        delete:'删除',
        admin:'管理员',
        member:'成员',
        groupMangement:'分组管理',
        administratorTransfer:'转移管理员',
        administratorTransferTips:'请选择待转移用户',
        administratorTransferQuestion:'原始管理员将不具有该商店的管理访问权限',
        groupMangementView:'分组管理-查看',
        groupCode:'分组编号',
        groupName:'分组名称',
        dissolveItQuestion:'是否确认解散?',
        dissolvedSuccessfully:'解散成功',
        pleaseDeleteTheMembersBeforeDissolution:'请删除成员后解散！',
        meshNetworkCount:'网格网络数',
        latestLoginIP:'最新登录IP',
        relatedMeshNetworkCount:'关联网格网络组',
        relatedMeshAdminCount:'其中担任管理员',
        theUserCanBeDeletedOnlyAfterAssociationInformationIsDeleted:'删除以上关联信息后方可删除该用户'
    },
    device:{
        deviceName:'设备名称',
        uuid:'UUID',
        deviceCode:'设备编码',
        meshId:'网格ID',
        country:'国家',
        createDate:'创建日期',
        meshName:'网格名称',
        address:'地址'
    },
    firmware:{
        releaseVersion:'发布版',
        factoryVersion:'工厂版',
        developmentVersion:'开发版',
        versionNumber:'版本号',
        uploadDate:'上传日期',
        updateContentChinese:'更新内容(中文)',
        updateContentEnglish:'更新内容(英文)',
        firmwareType:'固件类型',
        esp32Camera:'ESP32带传感器',
        telinkNoCamera:'Telink无传感器',
        meta2Camera:'Meta2单传感器',
        meta2NoCamera:'Meta2无传感器',
        meta2DualCamera: 'Meta2双传感器',
        meta2C10: 'Meta2 C10版本',
        dataRelay: '数据中继',
        scenePannel: '场景面板',
        switchPannel: '开关面板',
        rkSceneController: 'RK场景控制器',
        rkPeopleController: 'RK客流控制器',
        handheldNoCamera: '手持无传感器',
        handheldCamera: '手持单传感器',
        handheldDualCamera: '手持双传感器',
        metaUniNoCamera: 'MetaUni无传感器',
        metaUniCamera: 'MetaUni单传感器',
        metaUniDualCamera: 'MetaUni双传感器',
        
        lunaNoCamera: '贝多芬无传感器',
        lunaCwNoCamera: '贝多芬双色温无传感器',
        auraNoCamera: '贝多芬磁吸无传感器',
        lukaNoCamera: '宙斯斗胆无传感器',
        auraCamera: '贝多芬磁吸单传感器',
        lukaCamera: '宙斯斗胆单传感器',
        lukaUniCamera: '宙斯斗胆双色温单传感器',
        musesCamera: '缪斯单传感器',
        musesDualCamera: '缪斯双传感器',
        buluceTwoTape: '步路思双路灯带',
        kanoCamera: '宙斯单传感器',
        kanoDualCamera: '宙斯双传感器',
        
        newType:'新型',
        newFirmware:'新型固件',
        json:'JSON',
        isForceUpdate:'是否强制更新',
        addReleaseFirmware:'新增发布固件',
        addFactoryFirmware:'新增工厂固件',
        addDevelopmentFirmware:'新增开发固件',
        verify:'验证',
        firmwareVerifyItem0:'无需验证',
        firmwareVerifyItem1:'未验证',
        firmwareVerifyItem2:'已验证',
        firmwareName:'名称',
        firmwareProduct:'所属产品',
        firmwareModule:'所属模块',
        firmwareVerify:'状态',
    },
    apps:{
        android:'安卓',
        androidInternational:'安卓国际版',
        ios:'苹果',
        iosInternational:'苹果国际版',
        versionNumber:'版本号',
        uploadDate:'上传日期',
        updateContentChinese:'更新内容(中文)',
        updateContentEnglish:'更新内容(英文)',
        scope:'区域',
        domestic:'国内版',
        international:'国际版',
        zhimouji:'智谋纪',
        caimeta:'Caimeta',
        sensetrack:'Sensetrack',
        proled:'Proled',
        avolux:'Avolux',
        hera:'Hera',
        hidealite:'Hidealite',
        selectScope:'选择范围',
        isForceUpdate:'是否强制更新',
        setDownloadVersion:'设为下载版本',
        currentDownloadVersion:'当前下载版本',
        addAndroidApp:'新增安卓应用',
        addAndroidInternationalApp:'新增安卓国际版应用',
        addIosApp:'新增苹果应用',
        addIosInternationalApp:'新增苹果国际版应用'
    },
    statistics:{
        searchType:'查询类型',
        year:'年',
        month:'月',
        week:'周',
        day:'日',
        deviceName:'设备名称',
        LuminaireName:'灯具名称',
        storeName:'店铺名称',
        date:'日期',
        changingTrendOfCustomerFlow:'客流量变化趋势',
        RatioChartOfCustomerFlow:'客流量占比图',
        statisticalAverageOfStorePopularity:'店铺热度统计平均值',
        rankOfCommodityPopularity:'单灯总热度排名',
        statisticalAllOfStorePopularity:'所有灯热度',
        selectOneOrMultipleStores:'选择一个或多个店铺',
        selectOneStore:'选择店铺',
        under5Seconds:'5s以下',
        above60Seconds:'60s以上',
        personTime:'人次',
        totalNumber:'总人数',
        luminaire:'灯具',
        luminaires:'灯具',
        time:'时间',
        storeName:'店铺名称',
        exportText:'导出',
        exportStoreDataQuestion:'是否确认导出所有店铺热度数据？',
        exportLuminaireDataQuestion:'是否确认导出所有单灯热度数据？',
        exportStoreDataFilename:'店铺热度统计平均值数据.xlsx',
        exportLuminaireDataFilename:'单灯总热度排名数据.xlsx',
        mapConfig:"图层配置",
        noDataAvailable:'暂无数据',
        light:'灯名：',
        peopleCount:'客流：',
        lightTheme:'灯主题风格',
        changenMap:'更换平面图层',
        automaticQuery:'自动查询',
        realtime:'最新'
    },
    people:{
        storeGate:'店铺门口',
        date:'日期',
        walkInCountAnalysis:'进店客流分析',
        walkInCount:'进店客流',
        walkInCountTotal:'进店客流总计',
        walkInCount2:'进店客流',
        walkOutCount:'出店客流',
        passingFlowAnalysis:'客流分析',
        passingFlow:'客流',
        passingFlow2:'通过客流',
        mom:'比上月',
        dod:'比昨日',
        recent:'当日',
        zoneInformation:'区域信息',
        zoneDeviceName:'区域设备名称',
        zoneRotate:'区域旋转',
        clockwise0:'0°',
        clockwise90:'顺时针 90°',
        clockwise180:'顺时针 180°',
        clockwise270:'顺时针 270°',
        storePeopleCountMax:'店铺最大热度',
        storePeopleCountMin:'店铺最小热度',
        zonePeopleCountMax:'区域最大热度',
        zonePeopleCountMin:'区域最小热度',
    },
    feedback:{
        newRegistrant:'新增注册人数',
        totalUser:'总人数',
        feedback:'反馈意见',
        inputSearchEmail:'请输入查询邮箱'
    },
    tools:{
        tips:'系统提示',
        confirm:'确定',
        cancel:'取消',
        confirmLong:'确 定',
        cancelLong:'取 消',
        yes:'是',
        no:'否'
    },
    third:{
        helpPage:"智谋纪"
    },
    text: {
        message: 'Smart Lighting Cloud Platform',
        language: 'Language'
    },
    ...zhLocale //或者用 Object.assign({message:'你好'},zhLocale)

}

export default zh

